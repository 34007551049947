<div class="overlay-container">
  @if (shouldShowOverlay()) {
    <div class="overlay">
      <ng-content></ng-content>
    </div>
  }
  @if (step.stepDefinition.type_data.choices.length > 2) {
    <mat-action-list>
      @for (choice of step.stepDefinition.type_data.choices; track choice; let index = $index) {
        <mat-list-item (click)="stepActionHandled.emit({ choice: choice.title })">
          <mat-icon style="color: var(--primary-color)" matListItemIcon>label_important</mat-icon> {{ choice.title }}
        </mat-list-item>
      }
    </mat-action-list>
  }

  @if (itemsInCart && ![324, 322].includes(step.step_definition_id)) {
    <ng-container *transloco="let t">
      <mat-card>
        <mat-card-content>
          @for (item of itemsInCart; track item) {
            <div class="detailed-panel width-100 margin-xxs-bottom radius-4px">
              <div class="flex column no-gap gap-xs space-between">
                <span class="detailed-header width-100">
                  {{ item.equipment.equipmentName }}
                  <div class="flex space-between align-center">
                    <span class="mat-expansion-panel-header-description">
                      @if (item.equipment.additionalInformation !== '') {
                        {{ item.equipment.additionalInformation }}
                        <br />
                      }
                      {{ item.equipment.packingUnit }}
                      <br />
                      {{ item.equipment.deliveryTime | convertDays }}
                      @if (capabilities | capabilityCheck: Capability.View.Equipment_Order_Quantity : true) {
                        <br />
                        {{ t('views.equipment.quantity') }}: {{ item.quantity }}
                      }
                    </span>
                    <img class="circle" [src]="computePath(item.equipment)" />
                  </div>
                </span>
              </div>
            </div>
          }
        </mat-card-content>
      </mat-card>
    </ng-container>
  }
</div>
